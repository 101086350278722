<template>
  <div class="flex gap-5" :class="{ 'max-w-3xl mx-auto': !showOriginal && !wide }">
    <div class="flex flex-1 flex-col gap-1 p-3">
      <fw-message v-if="!permissions.read" type="error">
        {{ $t('no_read_permissions') }}
      </fw-message>
      <fw-message v-else-if="!isOriginal && !loading" type="error">
        {{ $t('use_translation_mode') }}
        <fw-button @click.native="openTranslationMode()">{{ $t('use_translation_mode_btn') }}</fw-button>
      </fw-message>
      <div v-else-if="loading" class="flex flex-col gap-2 animate-pulse">
        <div class="bg-gray-200 rounded-md h-12"></div>
        <div class="bg-gray-200 rounded-md h-52"></div>
      </div>
      <div
        v-else
        class="bg-white rounded-md relative document-page"
        :class="{ 'pb-7 py-2.5': !paddingless, 'border border-gray-100': !borderless }"
      >
        <BlockChapters
          v-if="showChapters"
          :version="version"
          @select-document-block="selectDocumentBlock"
          @select-block="x => selectBlock(x.data, x.scroll)"
        />
        <div v-if="showTitle" class="flex gap-2 items-center relative pt-4" @click="selectDocumentBlock()">
          <div
            class="absolute cursor-pointer z-10 right-0.5 top-0 items-center -mr-px flex gap-2 text-xs text-gray-500 opacity-0 transition-all"
          >
            <fw-tag
              size="xs"
              :type="
                status == 'draft'
                  ? 'light-orange'
                  : status == 'published'
                  ? 'light-primary'
                  : status == 'closed'
                  ? 'light-gray'
                  : 'light-blue'
              "
              >{{ statesLabes[language][status] }}</fw-tag
            >
            <fw-icon-discuss class="w-4 h-4" />
            <span class="block_comments w-5 inline-block text-left" data-id="document">0</span>
          </div>
          <editor-content id="title" class="titleeditor main-text" :editor="titleEditor" />
        </div>
        <editor-content class="main-text body-editor" :editor="editor" />
        <div
          v-show="editable && selectedNode"
          class="main-block-options z-10 flex items-center justify-end select-none absolute top-0 left-[-2.75rem]"
          contenteditable="false"
        >
          <b-dropdown ref="addblock" class="add-block" style="z-index: 11" aria-role="list" position="is-bottom-right">
            <template #trigger="{ active }">
              <button class="h-6 w-6 border border-gray-200 rounded-md flex items-center justify-center">
                <fw-icon-add-line class="w-4 h-4 text-gray-300" />
              </button>
            </template>
            <b-dropdown-item paddingless aria-role="listitem">
              <fw-button type="simple" size="sm" label="Comentar" @click.native="commentBlock()">
                <template #icon>
                  <fw-icon-message class="w-5 h-5" />
                </template>
                Comentar
              </fw-button>
            </b-dropdown-item>
            <div class="leading-5 text-xs font-semibold text-gray-400 px-2">INSERIR CONTEÚDO</div>
            <EditorBlockItem
              v-for="(block, b) in editorBlocks"
              :key="'addblock' + b"
              :language="language"
              :item="{ type: block, name: $t('blocks.' + block), attrs: { level: 1 } }"
              @click.native="addBlock(block)"
            />
            <div class="leading-5 text-xs font-semibold text-gray-400 px-2">OUTRAS OPÇÕES</div>
            <b-dropdown-item paddingless aria-role="listitem">
              <fw-button label="Eliminar" type="simple" size="sm" @click.native="addBlock('duplicate')">
                <template #icon>
                  <fw-icon-copy class="w-5 h-5" />
                </template>
                {{ $t('duplicate') }}
              </fw-button>
            </b-dropdown-item>
            <b-dropdown-item paddingless aria-role="listitem">
              <fw-button label="Eliminar" type="simple" size="sm" @click.native="deleteBlock()">
                <template #icon>
                  <fw-icon-trash class="w-5 h-5" />
                </template>
                {{ $t('delete') }}
              </fw-button>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div
        v-if="isEmpty && permissions.write && isOriginal && !loading"
        class="bg-gray-100 border bg-opacity-70 border-opacity-70 border-gray-200 rounded-md py-2 px-3 mt-2 transition-all hover:opacity-100 opacity-40"
      >
        <fw-label>{{ $t('instructions') }}</fw-label>
        <EditorBlockItem
          :language="language"
          :item="{
            type: 'heading',
            name: $t('subtitle'),
            attrs: {
              level: 1
            }
          }"
          @click.native="addContent('heading')"
        />
        <EditorBlockItem
          :language="language"
          :item="{
            type: 'image',
            name: $t('image')
          }"
          @click.native="addContent('image')"
        />
        <EditorBlockItem
          :language="language"
          :item="{
            type: 'video',
            name: $t('video')
          }"
          @click.native="addContent('video')"
        />
        <EditorBlockItem
          v-if="false"
          :language="language"
          :item="{
            type: 'taskList',
            name: $t('task_list')
          }"
          @click.native="addContent('taskList')"
        />
      </div>
    </div>
    <div ref="menu-overtext" class="menu-one editor-menu">
      <div v-if="editor" class="flex gap-1">
        <button
          :class="{ 'is-active': editor.isActive('bold') }"
          @click="
            editor
              .chain()
              .focus()
              .toggleBold()
              .run()
          "
        >
          <fw-icon-bold class="h-5 w-5 text-gray-600" />
        </button>
        <button
          :class="{ 'is-active': editor.isActive('italic') }"
          @click="
            editor
              .chain()
              .focus()
              .toggleItalic()
              .run()
          "
        >
          <fw-icon-italic class="h-5 w-5 text-gray-600" />
        </button>
        <button
          :class="{ 'is-active': editor.isActive('underline') }"
          @click="
            editor
              .chain()
              .focus()
              .toggleUnderline()
              .run()
          "
        >
          <fw-icon-underline class="h-5 w-5 text-gray-600" />
        </button>
        <button
          :class="{ 'is-active': editor.isActive('strike') }"
          @click="
            editor
              .chain()
              .focus()
              .toggleStrike()
              .run()
          "
        >
          <fw-icon-strike class="h-5 w-5 text-gray-600" />
        </button>
        <div class="w-0.5 border-r mr-0.5 border-gray-200 my-1"></div>
        <button
          :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
          @click="
            editor
              .chain()
              .focus()
              .toggleHeading({ level: 1 })
              .run()
          "
        >
          <fw-icon-heading-1 class="h-5 w-5 text-gray-600" />
        </button>
        <button
          :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
          @click="
            editor
              .chain()
              .focus()
              .toggleHeading({ level: 2 })
              .run()
          "
        >
          <fw-icon-heading-2 class="h-5 w-5 text-gray-600" />
        </button>
        <button
          :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
          @click="
            editor
              .chain()
              .focus()
              .toggleHeading({ level: 3 })
              .run()
          "
        >
          <fw-icon-heading-3 class="h-5 w-5 text-gray-600" />
        </button>
        <button
          @click="
            editor
              .chain()
              .focus()
              .unsetAllMarks()
              .run()
          "
        >
          <fw-icon-format-clear class="h-5 w-5 text-gray-600" />
        </button>
        <div class="w-0.5 border-r mr-0.5 border-gray-200 my-1"></div>
        <button
          :class="{ 'is-active': editor.isActive('code') }"
          @click="
            editor
              .chain()
              .focus()
              .toggleCode()
              .run()
          "
        >
          <fw-icon-code class="h-5 w-5 text-gray-600" />
        </button>
        <button
          :class="{ 'is-active': editor.isActive('superscript') }"
          @click="
            editor
              .chain()
              .focus()
              .toggleSuperscript()
              .run()
          "
        >
          <fw-icon-superscript class="h-5 w-5 text-gray-600" />
        </button>
        <button
          :class="{ 'is-active': editor.isActive('subscript') }"
          @click="
            editor
              .chain()
              .focus()
              .toggleSubscript()
              .run()
          "
        >
          <fw-icon-subscript class="h-5 w-5 text-gray-600" />
        </button>
        <div class="w-0.5 border-r mr-0.5 border-gray-200 my-1"></div>
        <button :class="{ 'is-active': editor.isActive('link') }" @click="setLink()">
          <fw-icon-link class="h-5 w-5 text-gray-600" />
        </button>
        <button
          v-if="editor.isActive('link')"
          @click="
            editor
              .chain()
              .focus()
              .unsetLink()
              .run()
          "
        >
          <fw-icon-unlink class="h-5 w-5 text-gray-600" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
//import RecordFileEntry from '@/fw-modules/fw-core-vue/ui/components/form/RecordFileEntry'
//import FormattedText from '@/fw-modules/fw-core-vue/ui/components/form/FormattedText'
import { Editor, EditorContent } from '@tiptap/vue-2'
import { isTextSelection } from '@tiptap/core'
import BubbleMenuExtension from '@tiptap/extension-bubble-menu'
import EditorBlockItem from '../editor/EditorBlockItem.vue'
import Subscript from '@tiptap/extension-subscript'
import Superscript from '@tiptap/extension-superscript'
import Focus from '@tiptap/extension-focus'
import StarterKit from '@tiptap/starter-kit'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Collaboration from '@tiptap/extension-collaboration'
import { isChangeOrigin } from '@tiptap/extension-collaboration'
import UniqueID from '@tiptap-pro/extension-unique-id'
import FileHandler from '@tiptap-pro/extension-file-handler'
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import CustomImage from '@/fw-modules/fw-core-vue/pages/components/editor/customImage'
import CustomFiles from '@/fw-modules/fw-core-vue/pages/components/editor/customFiles'
import CustomVideo from '@/fw-modules/fw-core-vue/pages/components/editor/customVideo'
import YoutubeVideo from '@/fw-modules/fw-core-vue/pages/components/editor/youtubeVideo'
import { TrailingNode } from '@/fw-modules/fw-core-vue/pages/components/editor/trailingNode'
import Dropcursor from '@tiptap/extension-dropcursor'
import CollaborationCursor from '@tiptap/extension-collaboration-cursor'
import Link from '@tiptap/extension-link'
import { HocuspocusProvider } from '@hocuspocus/provider'
import Suggestions from '@/fw-modules/fw-core-vue/pages/components/editor/blockSuggestions'
import suggestion from '@/fw-modules/fw-core-vue/pages/components/editor/suggestions.js'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import ServicePages from '../../services/ServicePages'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import 'tippy.js/animations/shift-toward.css'
import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import BlockChapters from '../blocks/BlockChapters.vue'
export default {
  components: {
    EditorContent,
    EditorBlockItem,
    BlockChapters
  },
  props: {
    editable: {
      type: Boolean,
      default: true
    },
    paddingless: {
      type: Boolean,
      default: false
    },
    borderless: {
      type: Boolean,
      default: false
    },
    page: {
      type: Object,
      required: true
    },
    sideBySide: {
      type: Boolean,
      default: false
    },
    users: {
      type: Object
    },
    version: {
      type: Object,
      required: true
    },
    validations: {
      type: Object
    },
    usersEditing: {
      type: Object
    },
    wide: {
      type: Boolean,
      default: false
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    showChapters: {
      type: Boolean,
      default: true
    },
    editorBlocks: {
      type: Array,
      default: () => [
        'paragraph',
        'heading',
        'image',
        'files',
        'video',
        'youtube',
        'blockquote',
        'codeBlock',
        'bulletList',
        'orderedList'
      ]
    },
    formatOptions: {
      type: Array,
      default: () => [
        'bold',
        'italic',
        'underline',
        'strike',
        'link',
        'blockquote',
        'code',
        'bulletList',
        'orderedList',
        'heading'
      ]
    },
    comments: {
      type: Object,
      default: () => {}
    },
    commentsDocument: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chapters: [],
      filesToUpload: [],
      //blocks: [],
      blocksHeight: [],
      originalBlocksHeight: [],
      originalVersionContent: null,
      dirtyBlocks: new Set(),
      dropPlaceholderOptions: {
        className: 'drop-preview',
        animationDuration: '150',
        showOnTop: true
      },
      loading: true,
      showOriginal: false,
      loadingOriginal: false,
      editor: null,
      titleEditor: null,
      provider: null,
      hpstatus: 'connecting',
      isEmpty: false,
      noPermissions: false,
      permissions: {
        write: true,
        read: true
      },
      firstFocus: true,
      firstFocusTitle: true,
      statesLabes: {
        pt: {
          draft: 'Rascunho',
          review: 'Em revisão',
          closed: 'Bloqueado',
          published: 'Publicado',
          deleted: 'Eliminado',
          archived: 'Arquivado'
        },
        en: {
          draft: 'Draft',
          review: 'Under review',
          closed: 'Locked',
          published: 'Published',
          deleted: 'Deleted',
          archived: 'Archived'
        }
      },
      selectedNode: null,
      chapterProcessed: false
    }
  },
  computed: {
    status() {
      return this.version.state
    },
    language() {
      return this.$i18n.locale
    },
    currentUser() {
      return {
        name: this.me.name,
        color: this.getRandomColor()
      }
    },
    originalVersion() {
      return this.page != null && this.page.versions != null && this.page.versions.length > 0
        ? this.page.versions.filter(el => el.language == 'pt')[0]
        : null
    },
    versions() {
      return this.page != null && this.page.versions != null ? this.page.versions : []
    },
    versionKey() {
      //from router param
      return this.$route.params.version
    },
    editorKey() {
      return this.page.key + '_' + this.version.key
    },
    blocks() {
      return this.version.blocks
    },
    me() {
      return this.$store.state.session.user
    },
    userToken() {
      return this.$store.state.session.token
    },
    isOriginal() {
      return this.version.is_original
    }
  },
  watch: {
    userToken() {
      //prepare editor
      if (this.editor == null) {
        this.prepareEditor()
      }
    }
  },
  mounted() {
    this.prepareEditor()
  },
  beforeDestroy() {
    this.titleEditor?.destroy()
    this.editor?.destroy()
    this.provider?.destroy()
  },
  methods: {
    commentBlock() {
      this.$emit('new-comment', this.selectedNode.attrs.id)
    },
    deleteBlock() {
      if (this.selectedNode == null) {
        return
      }
      this.editor
        .chain()
        .focus()
        .deleteNode(this.selectedNode.type.name)
        .run()
    },
    addBlock(blockType) {
      //let endPos = this.selectedNode.getPos() + this.selectedNode.nodeSize
      //console.log('addBlock', nodeId, blockType, endPos)
      let content = {
        type: blockType
      }
      if (blockType == 'duplicate') {
        this.editor
          .chain()
          .focus()
          .insertContent(this.selectedNode.toJSON())
          //.insertContentAt(endPos, this.selectedNode.toJSON())
          //.focus(endPos)
          .run()
        return
      }
      if (blockType == 'heading') {
        content.attrs = { level: 1 }
      } else if (blockType == 'taskList') {
        content.content = [
          {
            type: 'taskItem',
            content: [
              {
                type: 'paragraph'
              }
            ]
          }
        ]
      } else if (blockType == 'orderedList' || blockType == 'bulletList') {
        content.content = [
          {
            type: 'listItem',
            content: [
              {
                type: 'paragraph'
              }
            ]
          }
        ]
      } else if (blockType == 'blockquote') {
        content.content = [
          {
            type: 'paragraph'
          }
        ]
      } else if (blockType == 'table') {
        //TODO:
        content.content = [
          {
            type: 'tableRow',
            content: [
              {
                type: 'tableCell',
                content: [
                  {
                    type: 'paragraph'
                  }
                ]
              },
              {
                type: 'tableCell',
                content: [
                  {
                    type: 'paragraph'
                  }
                ]
              }
            ]
          },
          {
            type: 'tableRow',
            content: [
              {
                type: 'tableCell',
                content: [
                  {
                    type: 'paragraph'
                  }
                ]
              },
              {
                type: 'tableCell',
                content: [
                  {
                    type: 'paragraph'
                  }
                ]
              }
            ]
          }
        ]
      }

      /*this.editor
        .chain()
        .insertContentAt(endPos, content)
        .focus(endPos)
        .run()*/
      this.editor
        .chain()
        .focus()
        .insertContent(content)
        //.insertContentAt(endPos, this.selectedNode.toJSON())
        //.focus(endPos)
        .run()
      this.$refs.addblock.toggle()
    },
    openTranslationMode() {
      this.$router.push({
        name: 'content-pages-translator',
        params: { key: this.page.key, version: this.originalVersion.key }
      })
    },
    createChapters() {
      //console.log('createChapters')
      //get all headings: h1, h2, h3
      let headings = document.querySelectorAll('.body-editor h1, .body-editor h2, .body-editor h3')
      //console.log('headings', headings)
      let chapters = []
      headings.forEach(el => {
        let level = el.tagName == 'H1' ? 1 : el.tagName == 'H2' ? 2 : 3
        //id from parent node
        let id = el.getAttribute('data-id')
        let text = el.innerText
        chapters.push({
          id: id,
          title: text,
          level: level,
          type: 'heading-' + level,
          chars: text.length,
          words: text.split(' ').length
        })
      })
      this.chapters = chapters
      setTimeout(() => {
        this.chapterProcessed = true
      }, 2000)
    },
    scrollToBlock(id) {
      console.log('scrollToBlock', id)
      if (id == 'title') {
        //scroll to top
        let container = document.querySelector('.inner-main-content')
        container.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        return
      }
      //scroll smothly to element with the id
      let element = document.querySelector(`[data-id="${id}"]`)
      let container = document.querySelector('.inner-main-content')
      if (element) {
        //console.log('scrolling to element', element)
        container.scrollTo({
          top: element.offsetTop - 100,
          behavior: 'smooth'
        })
      }
    },
    selectDocumentBlock(scroll = false) {
      let text = this.editor.getText()
      let words = text.split(' ').length
      let chars = text.length
      this.$emit('set-active-block', {
        segmentId: 'document',
        type: 'document',
        words: words,
        characters: chars
      })
      if (scroll) {
        this.scrollToBlock('title')
      }
    },
    selectBlock(data, scroll = false) {
      //onsole.log('selectBlock callback!', data)
      if (scroll) {
        this.scrollToBlock(data.id)
      }
      this.$emit('set-active-block', {
        segmentId: data.id,
        type: data.type,
        words: data.words,
        characters: data.chars
      })
      //check if its the same block as selectedNode
      if (this.selectedNode != null && this.selectedNode.attrs.id != data.id) {
        this.selectedNode = null
      }
    },
    prepareEditor() {
      if (this.userToken == null || typeof this.userToken == 'undefined') {
        console.error('User token not found')
        return
      }
      if (this.page == null || this.version == null) {
        console.error('Page or version not found')
        return
      }
      if (this.editor != null) {
        return
      }
      if (this.editable) {
        this.permissions.write = true
        this.permissions.read = true
      } else {
        this.permissions.write = false
        this.permissions.read = true
      }
      let self = this
      this.provider = new HocuspocusProvider({
        url: FwEnvConfig.apiUrlHocusPocus,
        name: 'pages:' + this.page.key + ':' + this.version.key + ':0',
        token: this.userToken,
        onAuthenticationFailed: () => {
          console.error('Authentication failed')
          self.permissions.write = false
          self.permissions.read = false
          //self.editor.isEditable = false
        },
        onSynced: () => {
          console.log('Document synced')
          self.loading = false
          self.$emit('document-loaded')
          //create chapters, wait 2000ms
          setTimeout(() => {
            // self.createChapters()
            self.checkTitle()
          }, 2000)
        }
      })
      console.log('MAIN EDITOR: ', this.page.key + ':' + this.version.key + ':0')
      this.provider.on('status', event => {
        this.hpstatus = event.status
      })
      this.editor = new Editor({
        autoFocus: false,
        editable: this.editable,
        editorProps: {
          attributes: {
            spellcheck: 'false'
          }
        },
        extensions: [
          TrailingNode,
          Focus.configure({
            className: 'active',
            mode: 'shallowest'
          }),
          CustomFiles,
          Underline,
          BubbleMenuExtension.configure({
            tippyOptions: {
              duration: 100,
              animation: 'shift-toward',
              placement: 'top-start',
              zIndex: 49,
              maxWidth: 'none'
            },
            element: self.$refs['menu-overtext'],
            shouldShow: ({ view, state, from, to }) => {
              //{ editor, view, state, oldState, from, to }
              const { doc, selection } = state
              const { empty } = selection

              // Sometime check for `empty` is not enough.
              // Doubleclick an empty paragraph returns a node size of 2.
              // So we check also for an empty text size.
              const isEmptyTextBlock = !doc.textBetween(from, to).length && isTextSelection(state.selection)
              //console.log('test', doc.nodeAt(from))
              const isTextBlock = isTextSelection(state.selection) //from != null && doc.nodeAt(from) != null ? doc.nodeAt(from).type.isText : false
              // When clicking on a element inside the bubble menu the editor "blur" event
              // is called and the bubble menu item is focussed. In this case we should
              // consider the menu as part of the editor and keep showing the menu
              const isChildOfMenu = document.activeElement.classList.contains('tiptap') //this.element.contains(document.activeElement)
              //console.log('isChildOfMenu', isChildOfMenu)
              const hasEditorFocus = view.hasFocus() || isChildOfMenu

              if (!hasEditorFocus || !isTextBlock || empty || isEmptyTextBlock || !this.editor.isEditable) {
                return false
              }

              return true
              //return true //editor.isActive('bold') || editor.isActive('italic') || editor.isActive('strike')
            }
          }),
          Placeholder.configure({
            placeholder: ({ node }) => {
              if (node.type.name === 'heading') {
                return this.$t('insert_subtitle')
              }
              return this.$t('write_here')
            }
          }),
          StarterKit.configure({
            // The Collaboration extension comes with its own history handling
            paragraph: true,
            heading: true,
            history: false,
            dropcursor: false,
            link: false,
            codeBlock: true,
            bulletList: true,
            orderedList: true,
            blockquote: true,
            draggable: true
          }),
          Superscript,
          Subscript,
          /*customBlockquote,
          //customParagraph,
          customCodeBlock,
          customBulletList,
          customOrderedList,*/
          // Register the document with Tiptap
          Collaboration.configure({
            document: this.provider.document,
            field: 'body'
          }),
          CollaborationCursor.configure({
            provider: this.provider,
            user: this.currentUser
          }),
          CustomImage.configure({
            HTMLAttributes: {
              class: 'editor-image'
            }
          }),
          CustomVideo,
          YoutubeVideo,
          Dropcursor.configure({
            color: '#666666',
            width: 1.5
          }),
          UniqueID.configure({
            types: [
              'heading',
              'paragraph',
              'image',
              'taskList',
              'table',
              'codeBlock',
              'video',
              'bulletList',
              'blockquote',
              'orderedList'
            ],
            filterTransaction: transaction => !isChangeOrigin(transaction)
          }),
          FileHandler.configure({
            allowedMimeTypes: ['image/png', 'image/jpeg', 'image/gif', 'image/webp'],
            onDrop: (currentEditor, files, pos) => {
              console.log('FileHandler onDrop', files)
              if (files.length === 0) {
                return
              }
              files.forEach(file => {
                const fileReader = new FileReader()
                fileReader.readAsDataURL(file)
                fileReader.onload = () => {
                  currentEditor
                    .chain()
                    .insertContentAt(pos, {
                      type: 'image',
                      attrs: {
                        src: fileReader.result
                      }
                    })
                    .focus()
                    .run()
                }
              })
            },
            onPaste: (currentEditor, files, htmlContent) => {
              console.log('FileHandler onPaste', files)
              files.forEach(file => {
                if (htmlContent) {
                  // if there is htmlContent, stop manual insertion & let other extensions handle insertion via inputRule
                  // you could extract the pasted file from this url string and upload it to a server for example
                  //console.log(htmlContent) // eslint-disable-line no-console
                  return false
                }
                const fileReader = new FileReader()
                fileReader.readAsDataURL(file)
                fileReader.onload = () => {
                  currentEditor
                    .chain()
                    .insertContentAt(currentEditor.state.selection.anchor, {
                      type: 'image',
                      attrs: {
                        src: fileReader.result
                      }
                    })
                    .focus()
                    .run()
                }
              })
            }
          }),
          Link.configure({
            openOnClick: false,
            autoLink: this.editorBlocks.includes('link')
          }),
          Suggestions.configure({
            onEnter: () => {},
            suggestion
          })
        ],
        onUpdate: ({ editor }) => {
          let json = editor.getJSON()
          console.log('onUpdate', json)
          if (json.content.length > 1) {
            this.isEmpty = false
          } else if (
            json.content.length == 1 &&
            (typeof json.content[0].content == 'undefined' || json.content[0].content.length == 0)
          ) {
            this.isEmpty = true
          } else {
            this.isEmpty = false
          }
          //create chapters with debounce
          this.createChapters()
        },
        availableContentBlocks: this.editorBlocks,
        pageKey: this.page.key,
        versionKey: this.version.key,
        selectBlockCallback: this.selectBlock,
        commentFunction: nodeId => {
          console.log('commentFunction')
          self.$emit('new-comment', nodeId)
        },
        onSelectionUpdate: ({ editor }) => {
          if (this.firstFocus) {
            this.firstFocus = false
            return
          }
          //get info of current selected node
          let parent = editor.state.selection.$head.parent
          console.log('state', editor.state)
          console.log('parent', parent)
          if (parent.type.name == 'doc') {
            //ignore selection on document
            return
          }
          this.selectedNode = parent
          //console.log('onSelectionUpdate', parent)
          let text = ''
          parent.content.content.forEach(el => {
            text += el.type.isText ? el.text : ''
          })
          let words = text.split(' ').length
          self.selectBlock({
            id: parent.attrs.id,
            type: parent.type.name,
            words: words,
            chars: parent.content.size
          })
          let mainOptions = document.querySelector('.main-block-options')
          let mainEditor = document.querySelector('.body-editor')
          //use data-id to identify the block
          let block = document.querySelector('[data-id="' + parent.attrs.id + '"]')
          if (block) {
            let blockTop = block.offsetTop
            let blockTopDocument = blockTop + mainEditor.offsetTop
            if (mainOptions) {
              mainOptions.style.top = blockTopDocument + 5 + 'px'
            }
          }
        },
        draggable: true,
        allowAddBlock: true,
        language: this.language
        //selection.$head.parent
      })
      if (!this.showTitle) return
      //this.version.title
      this.titleEditor = new Editor({
        autoFocus: false,
        editable: this.editable,
        extensions: [
          Collaboration.configure({
            document: this.provider.document,
            field: 'title'
          }),
          CollaborationCursor.configure({
            provider: this.provider,
            user: this.currentUser
          }),
          Document.extend({
            content: 'paragraph'
            //title: 'text*',
          }),
          Paragraph.extend({
            draggable: true
          }),
          Text.extend({
            draggable: true
          }),
          Placeholder.configure({
            placeholder: () => {
              return this.$t('insert_title')
            }
          })
        ],
        onUpdate: ({ editor }) => {
          if (this.firstFocusTitle) {
            this.firstFocusTitle = false
            return
          }
          let text = editor.getText()
          if (this.version.title && this.version.title != text) {
            this.$emit('title-changed', text)
          }
        },
        onFocus: () => {
          this.selectDocumentBlock()
        }
      })
    },
    checkTitle() {
      if (this.version.title != null) {
        this.titleEditor.getText() == '' && this.version.title != ''
          ? this.titleEditor.commands.setContent(this.version.title)
          : null
      }
    },
    getDocument(format) {
      if (format == 'text') {
        return this.titleEditor.getText() + '\n' + this.editor.getText()
      } else if (format == 'html') {
        return (this.titleEditor ? this.titleEditor.getHTML() : '') + (this.editor ? this.editor.getHTML() : '')
      } else if (format == 'json') {
        return {
          doc: this.editor.getJSON(),
          title: this.titleEditor.getJSON()
        }
      }
    },
    setLink() {
      const previousUrl = this.editor.getAttributes('link').href
      this.$buefy.dialog.prompt({
        title: 'URL',
        //optional
        inputAttrs: {
          value: previousUrl,
          placeholder: 'URL',
          required: false
        },
        confirmText: 'Guardar',
        cancelText: 'Cancelar',
        onConfirm: url => {
          // cancelled
          if (url === null) {
            return
          }
          if (url === '') {
            this.editor
              .chain()
              .focus()
              .extendMarkRange('link')
              .unsetLink()
              .run()
          } else {
            this.editor
              .chain()
              .focus()
              .extendMarkRange('link')
              .setLink({ href: url })
              .run()
          }
        }
      })
    },
    addContent(type) {
      let content = { type: type }
      if (type == 'heading') {
        content.attrs = { level: 1 }
      } else if (type == 'taskList') {
        content.content = [{ type: 'taskItem', attrs: { done: false }, content: [{ type: 'paragraph' }] }]
      } else if (type == 'bulletList' || type == 'orderedList') {
        content.content = [{ type: 'listItem', content: [{ type: 'paragraph' }] }]
      }
      this.editor
        .chain()
        .focus()
        .insertContent(content)
        .run()
    },
    getRandomElement(list) {
      return list[Math.floor(Math.random() * list.length)]
    },
    getRandomColor() {
      return this.getRandomElement(['#958DF1', '#F98181', '#FBBC88', '#FAF594', '#70CFF8', '#94FADB', '#B9F18D'])
    },
    async downloadFile(file) {
      const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
      utils.downloadFile(url, file.filename)
    },
    recalculateOneBlockHeight(i, which) {
      if (!((which == 'original' && this.originalBlocksHeight[i]) || this.blocksHeight[i])) {
        console.log('recalculateOneBlockHeight', i, 'not found')
        return
      }
      console.log('recalculateOneBlockHeight', i)
      let currentBlockHeight = document.querySelectorAll('.content-block')[i].offsetHeight
      //let originalBlockHeight = document.querySelectorAll('.content-block-original')[i].offsetHeight
      let gapHeight = 2.25 * parseFloat(getComputedStyle(document.documentElement).fontSize)
      this.originalBlocksHeight[i] = currentBlockHeight + gapHeight
    },
    calculateMinBlockHeight() {
      console.log('recalculate ALL block heights')
      let currentContentBlocks = document.querySelectorAll('.content-block')
      let currentOriginalContentBlocks = document.querySelectorAll('.content-block-original')
      let newheights = []
      let gapHeight = 2.25 * parseFloat(getComputedStyle(document.documentElement).fontSize)
      currentContentBlocks.forEach((el, i) => {
        //compare both blocks and save the biggest height
        let originalBlock = currentOriginalContentBlocks[i]
        let height = Math.max(el.offsetHeight, originalBlock.offsetHeight)
        height += gapHeight
        newheights.push(height)
      })
      this.blocksHeight = newheights
      this.originalBlocksHeight = [...newheights]
    },
    loadOriginalVersion() {
      this.loadingOriginal = true
      ServicePages.getPageVersionContent(this.page.key, this.originalVersion.key)
        .then(result => {
          console.log(result)
          this.originalVersionContent = result
          this.$nextTick(() => {
            this.calculateMinBlockHeight()
          })
        })
        .catch(e => {
          console.error(e)
        })
        .finally(() => {
          this.loadingOriginal = false
        })
    },
    toogleOriginal() {
      if (!this.showOriginal) {
        //load original version
        this.loadOriginalVersion()
      }
      this.showOriginal = !this.showOriginal
    },
    openVersion(versionKey) {
      if (this.editable) {
        this.$router.push({
          name: 'content-pages-editor-with-version',
          params: { key: this.page.key, version: versionKey }
        })
      } else {
        this.$router.push({
          name: 'content-pages-viewer-with-version',
          params: { key: this.page.key, version: versionKey }
        })
      }
    },
    updateFile(blockIndex, fileKey) {
      console.log('updateFile', fileKey)
      this.dataChanged(blockIndex, null)
      this.$emit('file-updated', {
        blockIndex: blockIndex,
        fileKey: fileKey
      })
    },
    deleteFile(blockIndex, fileKey) {
      this.$buefy.dialog.confirm({
        title: 'Apagar ficheiro',
        message: 'Tem a certeza que deseja <b>eliminar</b> este ficheiro? Esta ação não pode ser desfeita.',
        confirmText: 'Apagar',
        type: 'is-danger',
        hasIcon: false,
        onConfirm: async () => {
          console.log('deleteFile', fileKey)
          this.dataChanged(blockIndex, null)
          this.$emit('file-deleted', {
            blockIndex: blockIndex,
            fileKey: fileKey
          })
        }
      })
    },
    getFileUrl(file) {
      return ServiceStorage.getFileUrl(file, null)
    },
    getThumbnail(file) {
      if (file) {
        return file.thumb_url_format
          .replace('{KEY}', file.key)
          .replace('{TOKEN}', file.token)
          .replace('{SIZE}', 'max2k')
          .replace('{FILENAME}', file.thumb_filename || file.filename)
      } else {
        return ''
      }
    },
    execCommand(key, cmd) {
      /*if (cmd == 'h1' || cmd == 'h2' || cmd == 'h3') {
        let block = this.blocks.find(b => b.key == key)
        if (block) {
          block.content.style = cmd
          this.$emit('mark-dirty', block.key)
          this.$emit('selected', cmd)
        }
      } else { */
      console.log('execCommand inside editor', key, cmd)
      this.$refs[key][0].execCommand(cmd)
      //}
    },
    async onDropSection(event) {
      console.log(event)
      let previousIndex = event.removedIndex
      this.$emit('change-order', event)
      try {
        let result = await ServicePages.reorderContentBlock(
          this.page.key,
          this.version.key,
          event.payload.key,
          event.addedIndex
        )
        console.log(result)
      } catch (e) {
        console.error(e)
        //restore previous order if not successful
        event.removedIndex = event.addedIndex
        event.addedIndex = previousIndex
        this.$emit('change-order', event)
      }
    },
    uploadFile(i, event) {
      console.log('UPLOAD RESULT')
      console.log(event)
      if (event.length > 0) {
        let serverFile = event[0].response.data.file
        console.log('serverFile', serverFile)
        this.$emit(
          'file-uploaded',
          i /*{
          filename: serverFile.filename,
          key: serverFile.key,
          description: '',
        }*/,
          serverFile
        )
        this.$emit('mark-dirty', this.blocks[i].key)
      }
    },
    async removeInput(i) {
      let blocksnapshot = this.blocks[i]
      this.$buefy.dialog.confirm({
        title: 'Apagar conteúdo',
        message: 'Tem a certeza que deseja <b>eliminar</b> este conteúdo? Esta ação não pode ser desfeita.',
        confirmText: 'Apagar',
        type: 'is-danger',
        hasIcon: false,
        onConfirm: async () => {
          this.$emit('remove-content', i)
          try {
            let result = await ServicePages.deleteContentBlock(this.page.key, this.version.key, blocksnapshot.key)
            console.log(result)
          } catch (e) {
            console.error(e)
            this.$emit('restore-block', i, blocksnapshot)
          }
        }
      })
    },
    getChildPayload(index) {
      return this.blocks[index]
    },
    dataChanged(blockIndex, event) {
      console.log('dataChanged, block: ' + blockIndex, event)
      if (this.blocks[blockIndex].content == null) {
        this.blocks[blockIndex].content = {}
      }
      if (this.blocks[blockIndex].type == 'text') {
        this.blocks[blockIndex]['content']['text'] = event
      } else if (this.blocks[blockIndex].type == 'image') {
        //this.blocks[blockIndex]['content']['text'] = this.blocks[blockIndex].content.text
      }
      this.$nextTick(() => {
        this.recalculateOneBlockHeight(blockIndex, 'original')
      })
      this.$emit('mark-dirty', this.blocks[blockIndex].key)
    }
  }
}
</script>
<style lang="scss">
.ProseMirror .more-options a {
  @apply text-gray-600;
  text-decoration: none;
}
.main-block-options a {
  @apply text-gray-600;
  text-decoration: none;
}
.main-block-options a:after {
  content: '';
}
.ProseMirror .more-options a:after {
  content: '';
}
.titleeditor {
  @apply w-full text-3xl font-bold px-3;
}

.main-editor.rtl .main-text .ProseMirror p {
  direction: rtl;
}

.main-editor.rtl .main-text .ProseMirror h1 {
  direction: rtl;
}

.main-editor.rtl .main-text .ProseMirror h2 {
  direction: rtl;
}

.main-editor.rtl .main-text .ProseMirror h3 {
  direction: rtl;
}

.main-editor.rtl .main-text .ProseMirror h4 {
  direction: rtl;
}

.main-editor .ProseMirror {
  padding: 6px 8px;
  @apply focus:outline-none bg-white rounded-md;
}

.titleeditor .is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
.editor-menu {
  @apply flex gap-1 rounded-md py-0.5 px-1 border border-gray-200 shadow-sm;
  background-color: #fafafa;
  button {
    @apply border border-transparent bg-gray-50 rounded-md px-0.5 h-8 w-8 text-center flex items-center justify-center opacity-80;

    &:hover {
      @apply opacity-100;
    }

    &.is-active {
      @apply opacity-100 bg-gray-200 border-gray-300;
    }
  }
}

.tiptap .is-editor-empty .content {
  min-height: 120px;
}
.tiptap div.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.ProseMirror ul.editor-task-list {
  padding-left: 0rem;
}
.no-draggable-content .draggable-item .block-options {
  display: none;
}
.draggable-item {
  @apply bg-white rounded-md min-h-7 relative pl-3 pr-3 py-0.5;

  .editor-drag-handle {
    width: 1rem;
    height: 1.2rem;
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16"><path fill-opacity="0.2" d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /></svg>');
    background-size: 0.7rem 0.7rem;
    cursor: grab;
    @apply shadow-sm bg-center bg-no-repeat bg-white rounded border border-gray-200 ml-1;
  }
  .block-options {
    @apply absolute top-0.5 text-gray-800 transition-all opacity-0;
    gap: 0.07rem;
    left: -3.35rem;
  }
  .content {
    flex: 1 1 auto;
  }
}
.draggable-item:hover,
.draggable-item.active {
  .block-options {
    @apply opacity-100;
  }
  .editor-drag-handle {
    @apply cursor-move;
  }
}

ul.editor-task-list {
  @apply list-none;
}
ul.editor-task-list li {
  @apply list-none flex items-center;
}
ul.editor-task-list li input {
  @apply border border-gray-200 outline-none rounded-sm w-4 h-4 mr-1;
}
ul.editor-task-list li div {
  @apply flex-1;
}
table.editor-table {
  @apply w-full border-collapse;
}
table.editor-table th,
table.editor-table td {
  @apply border border-gray-200;
}
table.editor-table td {
  @apply w-1/2;
}
.image-uploader .file-uploads {
  @apply py-10 w-full;
}
.change-image-uploader .file-uploads {
  @apply py-2 w-full;
}
.content-block .editor-drag-handle {
  @apply transition-all opacity-0;
}
.content-block:hover .editor-drag-handle {
  @apply transition-all opacity-100;
}
.content-block .add-content {
  @apply transition-all opacity-0;
}
.content-block:hover .add-content {
  @apply transition-all opacity-100;
}
.content-block-container {
  min-height: 15px;
  @apply relative;
}
.image-uploader label {
  z-index: 0 !important;
}
/* Give a remote user a caret */
.collaboration-cursor__caret {
  position: relative;
  margin-left: -1px;
  margin-right: -1px;
  border-left: 1px solid #0d0d0d;
  border-right: 1px solid #0d0d0d;
  word-break: normal;
  pointer-events: none;
}

/* Render the username above the caret */
.collaboration-cursor__label {
  position: absolute;
  top: -1.4em;
  left: -1px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  user-select: none;
  color: #0d0d0d;
  padding: 0.1rem 0.3rem;
  border-radius: 3px 3px 3px 0;
  white-space: nowrap;
}
</style>

<i18n lang="json">
{
  "pt": {
    "no_read_permissions": "Não tem permissões de leitura para este documento.",
    "no_write_permissions": "Não tem permissões de escrita para este documento.",
    "index": "Índice",
    "original_version": "Versão original",
    "show_original": "Mostrar original",
    "instructions": "Comece por adicionar conteúdo ao seu documento:",
    "subtitle": "Subtítulo",
    "text": "Texto",
    "image": "Imagem",
    "video": "Vídeo",
    "blockquote": "Citação",
    "code": "Bloco de código",
    "bullet_list": "Lista",
    "ordered_list": "Lista numerada",
    "heading": "Título",
    "task_list": "Lista de tarefas",
    "table": "Tabela",
    "insert_subtitle": "Insira subtítulo",
    "write_here": "Escreva aqui",
    "insert_title": "Insira título",
    "use_translation_mode": "Este documento é uma tradução. Pode usar o modo de tradução para editá-lo.",
    "use_translation_mode_btn": "Usar modo de tradução",
    "blocks": {
      "image": "Imagem",
      "video": "Vídeo",
      "blockquote": "Citação",
      "bulletList": "Lista",
      "orderedList": "Lista ordenada",
      "taskList": "Lista de tarefas",
      "table": "Tabela",
      "heading": "Título",
      "codeBlock": "Bloco de código",
      "paragraph": "Parágrafo",
      "files": "Secção de ficheiros",
      "youtube": "Vídeo do YouTube"
    },
    "duplicate": "Duplicar",
    "delete": "Eliminar",
    "comment": "Comentar",
    "content_block": "Bloco de conteúdo",
    "upload_image": "Carregar Imagem",
    "upload_video": "Carregar Vídeo",
    "delete_block_title": "Eliminar bloco",
    "delete_block_message": "Tem certeza que deseja eliminar este bloco?",
    "delete_block_confirm": "Eliminar",
    "delete_block_cancel": "Cancelar",
    "download_image": "Descarregar Imagem",
    "save": "Guardar",
    "add_video": "Adicionar Vídeo"
  },
  "en": {
    "no_read_permissions": "You don't have read permissions for this document.",
    "no_write_permissions": "You don't have write permissions for this document.",
    "index": "Index",
    "original_version": "Original version",
    "show_original": "Show original",
    "instructions": "Start by adding content to your document:",
    "subtitle": "Subtitle",
    "text": "Text",
    "image": "Image",
    "video": "Video",
    "blockquote": "Blockquote",
    "code": "Code block",
    "bullet_list": "Bullet list",
    "ordered_list": "Ordered list",
    "heading": "Heading",
    "task_list": "Task list",
    "table": "Table",
    "insert_subtitle": "Insert subtitle",
    "write_here": "Write here",
    "insert_title": "Insert title",
    "use_translation_mode": "This document is a translation. You can use translation mode to edit it.",
    "use_translation_mode_btn": "Use translation mode",
    "blocks": {
      "image": "Image",
      "video": "Video",
      "blockquote": "Block Quote",
      "bulletList": "Bullet List",
      "orderedList": "Ordered List",
      "taskList": "Todo List",
      "table": "Table",
      "heading": "Heading",
      "codeBlock": "Code Block",
      "paragraph": "Paragraph",
      "files": "Files section",
      "youtube": "YouTube Video"
    },
    "duplicate": "Duplicate",
    "delete": "Delete",
    "comment": "Comment",
    "content_block": "Content block",
    "upload_image": "Upload Image",
    "upload_video": "Upload Video",
    "delete_block_title": "Delete block",
    "delete_block_message": "Are you sure you want to delete this block?",
    "delete_block_confirm": "Delete",
    "delete_block_cancel": "Cancel",
    "download_image": "Download Image",
    "save": "Save",
    "add_video": "Add Video"
  }
}
</i18n>
