<template>
  <div class="hidden xl:flex w-52 2xl:w-64 left-7 text-sm flex-col items-start bottom-5 overflow-y-auto gap-1">
    <div v-if="chapters.length > 0" class="font-semibold text-gray-300 pb-0.5">
      {{ $t('index') }}
    </div>
    <div
      class="text-gray-500 hover:text-gray-700 text-sm cursor-pointer line-clamp-1 w-full overflow-hidden"
      @click="$emit('select-document-block', true)"
    >
      {{ version.title }}
    </div>
    <div
      class="transition-all flex flex-col gap-1"
      :class="{ 'opacity-0': chapters.length == 0, 'opacity-100': chapters.length > 0 }"
    >
      <div
        v-for="chapter in chapters"
        :key="chapter.id"
        class="text-gray-500 hover:text-gray-700 text-sm cursor-pointer line-clamp-1 w-full overflow-hidden"
        @click="$emit('select-block', { data: chapter, scroll: true })"
      >
        {{ chapter.level == 2 ? '&nbsp;&nbsp;&nbsp;' : chapter.level == 3 ? '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' : '' }}
        {{ chapter.title }}
      </div>
    </div>
    <fw-icon-loading v-if="!chapterProcessed" class="w-5 h-5 text-gray-500" />
  </div>
</template>

<script>
export default {
  props: {
    version: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      chapterProcessed: false,
      chapters: []
    }
  },

  mounted() {
    this.createChapters()
  },

  methods: {
    createChapters() {
      //console.log('createChapters')
      //get all headings: h1, h2, h3
      let headings = document.querySelectorAll('.body-editor h1, .body-editor h2, .body-editor h3')
      //console.log('headings', headings)
      let chapters = []
      headings.forEach(el => {
        let level = el.tagName == 'H1' ? 1 : el.tagName == 'H2' ? 2 : 3
        //id from parent node
        let id = el.getAttribute('data-id')
        let text = el.innerText
        chapters.push({
          id: id,
          title: text,
          level: level,
          type: 'heading-' + level,
          chars: text.length,
          words: text.split(' ').length
        })
      })
      this.chapters = chapters
      this.chapterProcessed = true
    }
  }
}
</script>
